<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('reports.billsReport')"
                icon="mdi-credit-card-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="print"
                    >
                        <v-icon>
                            mdi-printer-outline
                        </v-icon>
                        {{ $t("print") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form
                            class=""
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-checkbox
                                            class="mt-1"
                                            v-model="showAll"
                                            :label="
                                                $t('reports.withoutTimePeriod')
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-autocomplete
                                            v-model="selectedBillTypes"
                                            :items="billSettings"
                                            hide-details
                                            item-text="billCaption"
                                            item-value="billSettingGuid"
                                            :label="$t('bill.billType')"
                                            chips
                                            outlined
                                            dense
                                            deletable-chips
                                            multiple
                                            small-chips
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="currencyGuid"
                                            :items="currencies"
                                            @change="changeCurrency"
                                            item-text="currencySymbol"
                                            item-value="currencyGuid"
                                            :label="$t('voucher.currency')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="customerGuid"
                                            :items="customers"
                                            item-text="fullName"
                                            item-value="customerGuid"
                                            :label="$t('customer.fullName')"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="payStatus"
                                            :items="payStatusList"
                                            item-text="key"
                                            item-value="value"
                                            :label="$t('bill.payStatus')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            :rules="rules"
                                            persistent-hint
                                            v-model="fiscalYear"
                                            :items="fiscalYears"
                                            item-text="title"
                                            item-value="fiscalYearGuid"
                                            :label="$t('fiscalYear.fiscalYear')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getData"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                :items="bills"
                class="pa-5"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template slot="body.append">
                    <tr>
                        <th>{{ $t("total") }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            {{ totalAmount | currency("", 3) }}
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                            {{ totalNet | currency("", 3) }}
                        </th>
                        <th>
                            {{ totalReceived | currency("", 3) }}
                        </th>
                        <th>
                            {{ totalRemaining | currency("", 3) }}
                        </th>
                    </tr>
                </template>
                <template v-slot:[`item.totalAmount`]="{ item }">
                    {{ item.totalAmount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.totalExtra`]="{ item }">
                    {{ item.totalExtra | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.totalDiscount`]="{ item }">
                    {{ item.totalDiscount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.totalNet`]="{ item }">
                    {{ item.totalNet | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.receivedAmount`]="{ item }">
                    {{ item.receivedAmount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.remainingAmount`]="{ item }">
                    {{ item.remainingAmount | currency("", floatingPoints) }}
                </template>
                <template v-slot:[`item.billDate`]="{ item }">
                    {{ item.billDate | moment("yyyy-MM-DD") }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            floatingPoints: 0,
            showAll: false,
            panel: [0, 1],
            fiscalYears: [],
            fiscalYear: null,
            totalNet: null,
            totalAmount: null,
            totalReceived: null,
            totalRemaining: null,
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            loading: true,
            dialog: false,
            editedIndex: -1,
            currencyGuid: null,
            customerGuid: null,
            selectedBillTypes: null,
            payStatus: 2,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            headers: [
                {
                    text: this.$t("bill.billNumber"),
                    value: "billNumber"
                },
                {
                    text: this.$t("bill.billDate"),
                    value: "billDate"
                },
                {
                    text: this.$t("customer.fullName"),
                    value: "customerName"
                },
                {
                    text: this.$t("users.userName"),
                    value: "userName"
                },
                {
                    text: this.$t("bill.billType"),
                    value: "billType"
                },
                {
                    text: this.$t("bill.priceType"),
                    value: "priceType"
                },
                {
                    text: this.$t("bill.payType"),
                    value: "payType"
                },
                {
                    text: this.$t("bill.notes"),
                    value: "notes"
                },
                {
                    text: this.$t("bill.totalAmount"),
                    value: "totalAmount"
                },
                {
                    text: this.$t("bill.totalExtra"),
                    value: "totalExtra"
                },
                {
                    text: this.$t("bill.totalDiscount"),
                    value: "totalDiscount"
                },
                {
                    text: this.$t("bill.totalNet"),
                    value: "totalNet"
                },
                {
                    text: this.$t("bill.receivedAmount"),
                    value: "receivedAmount"
                },
                {
                    text: this.$t("bill.remainingAmount"),
                    value: "remainingAmount"
                }
            ],
            payStatusList: [
                { key: this.$t("payStatus.unpaid"), value: 0 },
                { key: this.$t("payStatus.paid"), value: 1 },
                { key: this.$t("payStatus.all"), value: 2 }
            ],
            billTypesList: {
                Purchase: this.$t("billTypes.Purchase"),
                Sale: this.$t("billTypes.Sale"),
                PurchaseReturn: this.$t("billTypes.PurchaseReturn"),
                SaleReturn: this.$t("billTypes.SaleReturn"),
                InstallmentsSale: this.$t("billTypes.installmentsSaleBill")
            },
            priceTypes: {
                0: this.$t("priceTypes.0"),
                1: this.$t("priceTypes.1"),
                2: this.$t("priceTypes.2"),
                3: this.$t("priceTypes.3"),
                4: this.$t("priceTypes.4"),
                5: this.$t("priceTypes.5")
            },
            payTypes: {
                1: this.$t("payTypes.cash"),
                2: this.$t("payTypes.delayed"),
                3: this.$t("payTypes.creditCard"),
                4: this.$t("payTypes.check")
            },
            bills: [],
            currencies: [],
            customers: [],
            billSettings: [],
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getFilterData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    methods: {
        close() {
            this.dialog = false;
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        getData() {
            try {
                if (this.$refs.form.validate()) {
                    var defaultFiscalYear =
                        this.fiscalYear == null
                            ? this.fiscalYears.filter(function(obj) {
                                  return obj.isActive == true;
                              })[0].fiscalYearGuid
                            : this.fiscalYear;

                    this.loading = true;
                    axios
                        .get("Bills/BillsReport", {
                            params: {
                                fiscalYearGuid: defaultFiscalYear,
                                currencyGuid:
                                    this.currencyGuid == null
                                        ? this.currencies.filter(function(obj) {
                                              return obj.isMain == true;
                                          })[0].currencyGuid
                                        : this.currencyGuid,
                                selectedBillTypes: this.selectedBillTypes,
                                payStatus: this.payStatus,
                                customerGuid: this.customerGuid,
                                fromDate: this.fromDate,
                                toDate: this.toDate,
                                showAll: this.showAll
                            }
                        })
                        .then(response => {
                            this.bills = response.data.data;

                            this.totalNet =
                                Math.round(
                                    (this.bills.reduce(
                                        (a, b) => a + (b["totalNet"] || 0),
                                        0
                                    ) +
                                        Number.EPSILON) *
                                        100
                                ) / 100;

                            this.totalAmount =
                                Math.round(
                                    (this.bills.reduce(
                                        (a, b) => a + (b["totalAmount"] || 0),
                                        0
                                    ) +
                                        Number.EPSILON) *
                                        100
                                ) / 100;

                            this.totalReceived =
                                Math.round(
                                    (this.bills.reduce(
                                        (a, b) =>
                                            a + (b["receivedAmount"] || 0),
                                        0
                                    ) +
                                        Number.EPSILON) *
                                        100
                                ) / 100;

                            this.totalRemaining =
                                Math.round(
                                    (this.bills.reduce(
                                        (a, b) =>
                                            a + (b["remainingAmount"] || 0),
                                        0
                                    ) +
                                        Number.EPSILON) *
                                        100
                                ) / 100;
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.dialog = false;
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        getFilterData() {
            try {
                axios
                    .get(
                        "Bills/GetBillSettings?id=00000000-0000-0000-0000-000000000000"
                    )
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.billSettings = response.data.data.map(item => {
                                return {
                                    billSettingGuid: item.billSettingGuid,
                                    billCaption: this.$t(
                                        "billTypes." + item.billType
                                    )
                                };
                            });
                            this.selectedBillTypes = this.billSettings.map(
                                a => a.billSettingGuid
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dialog = true;
                    });

                axios
                    .get("Customers/Get")
                    .then(response => {
                        this.customers = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                axios
                    .get("Currencies/Get")
                    .then(response => {
                        this.currencies = response.data.data;
                        this.currencyGuid = this.currencies.filter(function(
                            obj
                        ) {
                            return obj.isMain == true;
                        })[0].currencyGuid;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                axios
                    .get("FiscalYears/Get")
                    .then(response => {
                        this.fiscalYears = response.data.data;
                        var activeFiscalYear = this.fiscalYears.find(
                            x => x.isActive == true
                        );
                        if (activeFiscalYear != null)
                            this.fiscalYear = activeFiscalYear.fiscalYearGuid;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        print() {
            try {
                this.loading = true;
                var defaultFiscalYear =
                    this.fiscalYear == null
                        ? this.fiscalYears.filter(function(obj) {
                              return obj.isActive == true;
                          })[0].fiscalYearGuid
                        : this.fiscalYear;

                axios({
                    url: "Bills/PrintBillsReport",
                    method: "GET",
                    responseType: "blob",
                    params: {
                        fiscalYearGuid: defaultFiscalYear,
                        currencyGuid:
                            this.currencyGuid == null
                                ? this.currencies.filter(function(obj) {
                                      return obj.isMain == true;
                                  })[0].currencyGuid
                                : this.currencyGuid,
                        selectedBillTypes: this.selectedBillTypes,
                        payStatus: this.payStatus,
                        customerGuid: this.customerGuid,
                        fromDate: this.fromDate,
                        toDate: this.toDate,
                        showAll: this.showAll
                    }
                })
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", "تقرير الفواتير.pdf");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        changeCurrency(val) {
            this.floatingPoints = this.currencies.find(
                x => x.currencyGuid == val
            ).floatingPoints;
        }
    }
};
</script>
